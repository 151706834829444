@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: #f3f4f7 !important;
  font-family: 'Work Sans', sans-serif !important;
  overflow: auto !important;
  padding-right: unset !important;
  position: unset !important;
}
body {
  font-family: 'Work Sans', sans-serif !important;
  background: #f3f4f7 !important;
}

.sidebar-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
  height: 3px;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #18202f !important;
}

/* Handle */
.sidebar-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(89.99deg, #46bcb1 0.01%, #46bcb1 98.9%) !important;
}

/* Handle on hover */
.sidebar-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(89.99deg, #46bcb1 0.01%, #46bcb1 98.9%) !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.webkit_in {
  display: -webkit-inline-box;
}

.webkit_out {
  display: table-caption;
}

.chart canvas {
  height: 163px !important;
}

/*FILTER SCROLL*/

.filter-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 21px;
  height: 3px;
}

.filter-container::-webkit-scrollbar-track {
  background: #d7d7da !important;
}

/* Handle */
.filter-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: linear-gradient(89.99deg, #b4b4b4 0.01%, #b4b4b4 98.9%) !important;
}

/* Handle on hover */
.filter-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(89.99deg, #b4b4b4 0.01%, #b4b4b4 98.9%) !important;
}

.selected {
  background: lightgray;
  border-radius: 15px;
  padding: 12px;
}

.unselected {
  padding: 12px;
  opacity: 0.3;
}

/* CUSTOM COMMON RADIO BUTTON STYLE */
input[type='radio'] {
  /* accent-color: #1ca7d1; */
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfe1e1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #46bcb1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #46bcb1;
}

.custome-scroll-for-network::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  display: none !important;
}

.custom-text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.react-datepicker__input-container input {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  border: 1px solid #d1d5db;
  border-radius: 1.5rem;
  appearance: none;
  outline: none;
}

.react-datepicker__input-container input:focus {
  border-color: #1ca7d1;
}

.react-datepicker__close-icon::after {
  background-color: #99abb0 !important;
}

.react-datepicker__close-icon {
  margin-right: 0.2rem;
}

.date-proper-design .react-datepicker-popper {
  inset: unset !important;
  transform: unset !important;
  top: 152px !important;
  left: 0px !important;
  position: absolute;
}

.date-proper-design1 .react-datepicker-popper {
  top: 100px !important;
}
@media only screen and (max-width: 1023px) {
  .date-proper-design .react-datepicker-popper {
    top: 202px !important;
  }
  .date-proper-design1 .react-datepicker-popper {
    top: 140px !important;
  }
}
@media only screen and (max-width: 991px) {
  .date-proper-design .react-datepicker-popper {
    top: 152px !important;
  }
  .date-proper-design1 .react-datepicker-popper {
    top: 140px !important;
  }
}

@media only screen and (max-width: 639px) {
  .date-proper-design .react-datepicker-popper {
    top: 208px !important;
  }
  .date-proper-design1 .react-datepicker-popper {
    top: 150px !important;
  }
}
